<template>
  <div class="mt-4">
    <span class="font-weight-bold">Comentários: </span>

    <div class="mt-4">
      <v-textarea
        :value="value.therapistComments"
        outlined
        label="Comentários exclusivo para terapeutas (não será mostrado ao paciente)"
        rows="3"
        @input="(inputValue) => setValue(inputValue, 'therapistComments')"
        @blur="input"
      />
    </div>
    <div>
      <v-textarea
        :value="value.patientComments"
        outlined
        label="Comentários para o paciente"
        rows="3"
        @input="(inputValue) => setValue(inputValue, 'patientComments')"
        @blur="input"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        therapistComments: "",
        patientComments: "",
      }),
    },
  },
  data: () => ({
    therapistComments: "",
    patientComments: "",
  }),
  mounted() {
    this.therapistComments = this.value.therapistComments;
    this.patientComments = this.value.patientComments;
  },
  methods: {
    clear() {
      this.therapistComments = "";
      this.patientComments = "";
    },
    setValue(value, property) {
      this[property] = value;
    },
    input() {
      this.$emit("input", { ...this.$data });
    },
  },
};
</script>

<style></style>
